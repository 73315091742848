import React, { FC } from 'react';
import { Link } from 'gatsby';

import { getLocationLang } from 'gatsby-theme-dettol/src/utils/browser';
import { TLogoComponent } from 'gatsby-theme-dettol/src/components/common/Logo/models';
import 'gatsby-theme-dettol/src/components/common/Logo/Logo.scss';

const Logo: FC<TLogoComponent> = ({ brandSettings: { brandName } }) => {
  const pathLang = getLocationLang();
  const to = '/'.concat(pathLang);

  return (
    <strong className="dt-logo">
      <Link to={to}>
        <img width="100%" height="100%" src="/images/Dettol_new_logo_Korea.svg" alt={brandName} />
      </Link>
    </strong>
  );
};

export default Logo;
